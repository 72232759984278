(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
/*jslint node: true, browser: true */
'use strict';

var formIdPrefix = 'mktoForm_';
var cssPrefix = '.mkto';

var removeAll = function (array) {
    var i;
    for (i = 0; i < array.length; i += 1) {
        array[i].parentNode.removeChild(array[i]);
    }
    return array;
};

var removeClassAll = function (array, className) {
    var i;
    for (i = 0; i < array.length; i += 1) {
        array[i].classList.remove(className);
    }
    return array;
};

var unwrap = function (wrapper) {
    while (wrapper.firstChild) {
        wrapper.parentNode.insertBefore(wrapper.firstChild, wrapper);
    }
    wrapper.parentNode.removeChild(wrapper);
    return wrapper;
};
var unwrapAll = function (wrappers, removeEmpty) {
    var i;
    for (i = 0; i < wrappers.length; i += 1) {
        if (wrappers[i].firstChild || removeEmpty) {
            unwrap(wrappers[i]);
        }
    }
    return wrappers;
};

var moveAllToBottom = function (elements) {
    var i;
    for (i = 0; i < elements.length; i += 1) {
        elements[i].parentNode.appendChild(elements[i]);
    }
    return elements;
};

var isStylesheetMarketo = function (stylesheet) {
    var i, rule;

    for (i = 0; i < stylesheet.cssRules.length; i += 1) {
        rule = stylesheet.cssRules[i];

        if (rule.cssText.indexOf(cssPrefix) !== -1) {
            return true;
        }
    }

    return false;
};

var marketoFresh = function (form) {
    var i,
        stylesheets = document.styleSheets,
        stylesheet,
        formElement = document.getElementById(formIdPrefix + form.getId()),
        formChildren = formElement.getElementsByTagName('*');


    formElement.removeAttribute('style');

    for (i = 0; i < formChildren.length; i += 1) {
        formChildren[i].removeAttribute('style');
    }

    formElement.className = '';

    removeAll(formElement.querySelectorAll('.mktoClear'));
    removeAll(formElement.querySelectorAll('.mktoGutter'));
    removeAll(formElement.querySelectorAll('.mktoOffset'));
    removeAll(formElement.querySelectorAll('.mktoAsterix'));
    removeAll(formElement.querySelectorAll('style'));
    removeAll(formElement.querySelectorAll('label:empty'));
    //removeAll(formElement.querySelectorAll('label'));
    removeAll(formElement.querySelectorAll('.mktoPlaceholder:empty'));
    removeAll(formElement.querySelectorAll('.mktoFormRow:empty'));

    removeClassAll(formElement.querySelectorAll('.mktoHasWidth'), 'mktoHasWidth');
    removeClassAll(formElement.querySelectorAll('.mktoFormCol'), 'mktoFormCol');

    unwrapAll(formElement.querySelectorAll('.mktoCheckboxList'));
    unwrapAll(formElement.querySelectorAll('.mktoFormRow'));
    unwrapAll(formElement.querySelectorAll('.mktoButtonRow'));
    //unwrapAll(formElement.querySelectorAll('.mktoFieldDescriptor'));
    unwrapAll(formElement.querySelectorAll('.mktoButtonWrap'));
    unwrapAll(formElement.querySelectorAll('.mktoFieldWrap'));

    unwrapAll(
        [].slice.call(formElement.querySelectorAll('.mktoFormRow input[type="hidden"]'))
            .map(function (current) {
                return current.parentNode;
            })
    );

    moveAllToBottom(formElement.querySelectorAll('input[type="hidden"]'));

    for (i = stylesheets.length - 1; i >= 0; i -= 1) {
        stylesheet = stylesheets[i];

        if (stylesheet.href && stylesheet.href.match(/\.marketo\.com/)) {
            stylesheet.disabled = true;
        }

        if (!stylesheet.href && isStylesheetMarketo(stylesheet)) {
            stylesheet.disabled = true;
        }
    }
};


/**
 * Pass a Marketo form to clean it.
 * Pass a callback to receive a function that will clean a form, and then call your callback.
 * Can be used directly in MktoForms2.loadForm as the fourth parameter.
 * @callback formOrCallback Called after marketoFresh has finished.
 * @param   {(Object|function)} formOrCallback
 * @returns {(undefined|function} If a callback was supplied, a function will be returned that when called will clean a passed form, then call the callback.
 */
module.exports = function (formOrCallback) {
    if (typeof formOrCallback === 'function') {
        return function (form) {
            marketoFresh(form);
            formOrCallback(form);
        };
    }

    return marketoFresh(formOrCallback);
};

},{}],2:[function(require,module,exports){
//modal
'use strict';
var modals = document.querySelectorAll('a[data-modal]');
var activeModal = '';


for (var i = 0; i < modals.length; i++) {
    modals[i].addEventListener('click', function(e) {
        e.preventDefault();
        // Show modal
        activeModal = document.querySelector('#' + this.dataset.modal);
        if (activeModal.id === 'certificate') {
            activeModal.style.display = "block";
        } else {
            activeModal.style.display = "block";
        }
        // Close button
        activeModal.querySelector('.close').addEventListener('click', function(event) {
            activeModal.style.display = "none";
            activeModal.querySelector('.close').removeEventListener('click', function(e) {});
        });

        // Click off modal
        window.addEventListener('click', function(event) {
            if (event.target == activeModal) {
                console.log(activeModal);
                activeModal.style.display = "none";
                window.removeEventListener('click', function(e) {});
            }
        });
    });
}
},{}],3:[function(require,module,exports){
'use strict';
require('./Modal');
require('./typingAnimation');

/*
    ----- MARKETO -----
*/
require('./MarketoFresh');

$(document).ready(function(){

    function hashMenu() {
        if (window.location.href.indexOf('#7-solutions-ebook') > -1) {
            var sectionID = $('#7-solutions-ebook').offset().top;
            var headerHeight = $('.masthead').height();
            var sectionIDOffset = sectionID-(headerHeight+50);

            $('html,body').animate({scrollTop: sectionIDOffset},'slow');
        }
        if (window.location.href.indexOf('#3-ways-ebook') > -1) {
            var sectionID = $('#3-ways-ebook').offset().top;
            var headerHeight = $('.masthead').height();
            var sectionIDOffset = sectionID-(headerHeight+50);

            $('html,body').animate({scrollTop: sectionIDOffset},'slow');
        }
        if (window.location.href.indexOf('#mighty-guide') > -1) {
            var sectionID = $('#mighty-guide').offset().top;
            var headerHeight = $('.masthead').height();
            var sectionIDOffset = sectionID-(headerHeight+50);

            $('html,body').animate({scrollTop: sectionIDOffset},'slow');
        }
        if (window.location.href.indexOf('#solving-pm-challenges') > -1) {
            var sectionID = $('#solving-pm-challenges').offset().top;
            var headerHeight = $('.masthead').height();
            var sectionIDOffset = sectionID-(headerHeight+50);

            $('html,body').animate({scrollTop: sectionIDOffset},'slow');
        }
        if (window.location.href.indexOf('#5-ways-blog') > -1) {
            var sectionID = $('#5-ways-blog').offset().top;
            var headerHeight = $('.masthead').height();
            var sectionIDOffset = sectionID-(headerHeight+50);

            $('html,body').animate({scrollTop: sectionIDOffset},'slow');
        }
        if (window.location.href.indexOf('#covid-report') > -1) {
            var sectionID = $('#covid-report').offset().top;
            var headerHeight = $('.masthead').height();
            var sectionIDOffset = sectionID-(headerHeight+50);

            $('html,body').animate({scrollTop: sectionIDOffset},'slow');
        }

    }

    hashMenu();

});
},{"./MarketoFresh":1,"./Modal":2,"./typingAnimation":4}],4:[function(require,module,exports){
// values to keep track of the number of letters typed, which quote to use. etc. Don't change these values.
var i = 0,
    a = 0,
    isBackspacing = false,
    isParagraph = false;

// Typerwrite text content. Use a pipe to indicate the start of the second line "|".  
var textArray = [
    "01010101010101","01010101010101"
];
// Speed (in milliseconds) of typing.
var speedForward = 100, //Typing Speed
    speedWait = 1000, // Wait between typing and backspacing
    speedBetweenLines = 1000, //Wait between first and second lines
    speedBackspace = 25; //Backspace Speed

//Run the loop
typeWriter("output", textArray);

function typeWriter(id, ar) {
  var element = $("#" + id),
      aString = ar[a],
      eHeader = element.children("h2"), //Header element
      eParagraph = element.children("p"); //Subheader element
  
  // Determine if animation should be typing or backspacing
  if (!isBackspacing) {
    
    // If full string hasn't yet been typed out, continue typing
    if (i < aString.length) {
      
      // If character about to be typed is a pipe, switch to second line and continue.
      if (aString.charAt(i) == "|") {
        isParagraph = true;
        eHeader.removeClass("cursor");
        eParagraph.addClass("cursor");
        i++;
        setTimeout(function(){ typeWriter(id, ar); }, speedBetweenLines);
        
      // If character isn't a pipe, continue typing.
      } else {
        // Type header or subheader depending on whether pipe has been detected
        if (!isParagraph) {
          eHeader.text(eHeader.text() + aString.charAt(i));
        } else {
          eParagraph.text(eParagraph.text() + aString.charAt(i));
        }
        i++;
        setTimeout(function(){ typeWriter(id, ar); }, speedForward);
      }
      
    // If full string has been typed, switch to backspace mode.
    } else if (i == aString.length) {
      
      isBackspacing = true;
      setTimeout(function(){ typeWriter(id, ar); }, speedWait);
      
    }
    
  // If backspacing is enabled
  } else {
    
    // If either the header or the paragraph still has text, continue backspacing
    if (eHeader.text().length > 0 || eParagraph.text().length > 0) {
      
      // If paragraph still has text, continue erasing, otherwise switch to the header.
      if (eParagraph.text().length > 0) {
        eParagraph.text(eParagraph.text().substring(0, eParagraph.text().length - 1));
      } else if (eHeader.text().length > 0) {
        eParagraph.removeClass("cursor");
        eHeader.addClass("cursor");
        eHeader.text(eHeader.text().substring(0, eHeader.text().length - 1));
      }
      setTimeout(function(){ typeWriter(id, ar); }, speedBackspace);
    
    // If neither head or paragraph still has text, switch to next quote in array and start typing.
    } else { 
      
      isBackspacing = false;
      i = 0;
      isParagraph = false;
      a = (a + 1) % ar.length; //Moves to next position in array, always looping back to 0
      setTimeout(function(){ typeWriter(id, ar); }, 50);
      
    }
  }
}
},{}]},{},[3]);
